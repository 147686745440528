<div mat-dialog-title>
  <h2>{{"general.Crop Image" | translate}}</h2>

  <button mat-icon-button class="close" aria-label="Close" mat-dialog-close>
    <mat-icon class="pop-close">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="mat-dialog-body">

    <div *ngIf='!showCropper' class="dataLoading py-5">
      <mat-spinner class="m-auto" diameter="30"></mat-spinner>
    </div>

    <div [hidden]="!showCropper">
      <image-cropper
        [maintainAspectRatio]="true"
        [aspectRatio]="data.ratio"
        [onlyScaleDown]="true"
        [imageFile]="data.image"
        [(transform)]="transform"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
      ></image-cropper>

      <div class="my-3 text-center">
        <button mat-icon-button (click)="zoomOut()"><mat-icon>zoom_out</mat-icon></button>
        <button mat-icon-button (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>
      </div>
    </div>

  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button type="button" class="btn-secondary" mat-dialog-close>{{"general.Cancel" | translate}}</button>
  <button mat-button (click)="saveImage()" class="btn-primary">
    {{'swalMsg.Ok' | translate}}
  </button>
</mat-dialog-actions>
