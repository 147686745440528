import { Component, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { DirtyComponent } from '../../helpers/CanDeactivate/dirty-component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '../../helpers/custom.validator';
import { Patterns } from '../../helpers/patterns';
import { AuthAPIService } from '../../features/auth/services/auth-api.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-register',
  templateUrl: './vendor-register.component.html',
  styles: ``
})
export class VendorRegisterComponent extends DirtyComponent {
  loading = false;
  // formGroup!: FormGroup
  firstStepSubmit = false;
  secondStepSubmit = false;
  thirdStepSubmit = false;
  fourthStepSubmit = false;
  fifthStepSubmit = false;
  sixthStepSubmit = false;
  seventhStepSubmit = false;

  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'buffer';
  value: number = 1;
  bufferValue: number = 7;
  currentStepIndex = 1;

  logo: any;
  currentLogo: any;

  // Categories
  categoriesLoading = false;
  categoriesModel = { perPage: 50, page: 1, search: '' }
  categoriesList: any = [];
  selectedCategories: any = [];


  SearchMap: any
  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 22.2736308,
    lng: 70.7512555
  };
  zoom = 6;

  // City Select 
  loadingCities: boolean = false;
  citesModal = { perPage: 160, page: 1, search: '' }
  CitesList: any = []
  citiesCount: number = 0;
  City_Subscriptions: Subscription[] = []

  mapOptions!: google.maps.MapOptions;
  marker!: google.maps.MarkerOptions;
  MapCenter!: any
  MapZoom!: any

  constructor(override dialogRef: MatDialogRef<VendorRegisterComponent>,
    override translate: TranslateService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: AuthAPIService) {
    super(translate)
  }

  ngOnInit(): void {
    this.value = ((this.currentStepIndex) / this.bufferValue) * 100;
    this.buildForm();

    this.mapOptions = {
      mapId: 'customMap15',
      scrollwheel: true,
      disableDoubleClickZoom: false,
      mapTypeId: 'roadmap',
      zoomControl: true,
      fullscreenControl: true,
      mapTypeControl: false,
      streetViewControl: false
    };
    this.MapZoom = 7;

    this.MapCenter = {
      lat: 24.774265,
      lng: 46.738586
    }
  }

  buildForm() {
    // First Tab
    this.formGroup = this.formBuilder.group({
      // First Tab
      logo: [null],
      name_ar: [
        null, [
          Validators.required,
          CustomValidator.noWhiteSpace,
          Validators.pattern(Patterns.arTxtWithSpecial),
          Validators.maxLength(100)
        ]
      ],
      name_en: [
        null, [
          Validators.required,
          CustomValidator.noWhiteSpace,
          Validators.pattern(Patterns.enTxtWithSpecial),
          Validators.maxLength(100)
        ]
      ], have_online_store: [
        false, [
          Validators.required
        ]
      ],
      // Second Tab
      categories_ids: [[]],
      // Third Tab
      city_id: [
        null, [
          Validators.required
        ]
      ],
      address_ar: [
        null, [
          Validators.maxLength(100),
          Validators.pattern(Patterns.arTxtWithSpecial)
        ]
      ],
      address_en: [
        null, [
          Validators.maxLength(100),
          Validators.pattern(Patterns.enTxtWithSpecial)
        ]
      ],
      map_lat: [
        null,
        []
      ], // Jaddah Latitude
      map_lang: [
        null,
        []
      ],
      // Fourth Tab
      license_number: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.required,
          Validators.maxLength(20)
        ]
      ],
      license_attachment: [null],
      tax_number: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.required,
          Validators.maxLength(20)
        ]
      ],
      tax_attachment: [null],
      // Fifth Tab
      vendor_phone_number: [
        null, [
          Validators.maxLength(10),
          Validators.minLength(8)
        ]
      ],
      vendor_email: [
        null, [
          Validators.maxLength(100),
          Validators.pattern(Patterns.email)
        ]
      ],
      website: [
        null, [
          Validators.pattern(Patterns.website),
          Validators.maxLength(100)
        ]
      ],
      facebook_link: [
        null, [
          Validators.pattern(Patterns.website),
          Validators.maxLength(100)
        ]
      ],
      instagram_link: [
        null, [
          Validators.pattern(Patterns.website),
          Validators.maxLength(100)
        ]
      ],
      x_link: [
        null, [
          Validators.pattern(Patterns.website),
          Validators.maxLength(100)
        ]
      ],
      whatsapp: [
        null, [
          Validators.maxLength(10)
        ]
      ],
      snapchat_link: [
        null, [
          Validators.pattern(Patterns.website),
          Validators.maxLength(100)
        ]
      ],
      tiktok_link: [null, [
        Validators.pattern(Patterns.website),
        Validators.maxLength(100)
      ]
      ],
      // Six Tab
      full_name: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.required,
          Validators.maxLength(100)
        ]
      ],
      job_title: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.required,
          Validators.maxLength(100)
        ]
      ],
      phone_number: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.required,
          Validators.maxLength(8),
          Validators.minLength(8)
        ]
      ],
      email: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(Patterns.email)
        ]
      ],
      bank_name: [null, [
        Validators.maxLength(100)
      ]
      ],
      bank_address: [null, [
        Validators.maxLength(300)
      ]
      ],
      bank_account_name: [null, [
        Validators.maxLength(100)
      ]
      ],
      bank_account_number: [null, [
        Validators.maxLength(15)
      ]
      ],
      iban: [null, [
        Validators.maxLength(24)
      ]
      ],
      swift_code: [null, [
        Validators.maxLength(15)
      ]
      ]
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  // First tab Select Logo
  OnSelectLogo(Logo: any) {
    this.formGroup.patchValue({ logo: Logo });
    this.formGroup.controls['logo'].markAsDirty();
    this.formGroup.controls['logo'].setValue(Logo);
    this.currentLogo = Logo;
  }

  // Second Tab Get Categories
  getCategories() {
    this.categoriesLoading = true;
    this.apiService.getCategoriesWithoutPermission(this.categoriesModel).subscribe((data: any) => {
      this.categoriesLoading = false;
      this.categoriesList = data.data;
    })
  }

  // Third Tab Address
  GetCities() {
    this.City_Subscriptions.forEach(element => {
      element.unsubscribe();
    });

    this.loadingCities = true;
    this.City_Subscriptions.push(this.apiService.GetCities(this.citesModal).subscribe({
      next: (res: any) => {
        this.loadingCities = false;
        this.CitesList = this.CitesList.concat(res.data.list);
        this.citiesCount = res.data.total;
      },
      error: (error: any) => {
        this.loadingCities = false;

      }
    }))
  }

  dropSearch(data: any) {
    this.CitesList = [];
    this.citesModal.search = data?.term || '';
    this.GetCities();
  }

  OnClear() {
    this.formGroup.patchValue({ city_id: null })
    this.citesModal.search = ''
    this.CitesList = [];
    this.GetCities();
  }

  // Google Maps controls
  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
    this.marker = {
      position: {
        lat: Number(event.latLng?.lat()),
        lng: Number(event.latLng?.lng())
      }
    }
    this.MapZoom = null;
    this.formGroup.patchValue({
      map_lat: Number(event.latLng?.lat()),
      map_lang: Number(event.latLng?.lng())
    })
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  OnSelectFromSearch(event: any) {
    this.MapCenter = {
      lat: Number(event.location?.lat),
      lng: Number(event.location?.lng)
    };

    this.marker = {
      position: {
        lat: Number(event.location?.lat),
        lng: Number(event.location?.lng)
      }
    }

    this.MapZoom = 15;

    this.formGroup.patchValue({
      map_lat: Number(event.location?.lat),
      map_lang: Number(event.location?.lng)
    })
  }

  // Fourth Tab
  OnSelectLicense_attachment(files: any) {
    this.formGroup.patchValue({ license_attachment: files[0] });
    this.formGroup.controls['license_attachment'].markAsDirty();

  }
  OnSelectTax_attachment(files: any) {
    this.formGroup.patchValue({ tax_attachment: files[0] });
    this.formGroup.controls['tax_attachment'].markAsDirty();

  }

  // Back Button
  backStepper() {
    this.formGroup.controls['logo'].setValue(this.currentLogo)
    console.log(this.currentLogo)
    this.OnSelectLogo(this.currentLogo)
    this.currentStepIndex--
    this.value = ((this.currentStepIndex) / this.bufferValue) * 100;
    this.CitesList = []
  }

  // Next Button
  nextStepper(index: number) {
    this.currentStepIndex = index;
    if (this.currentStepIndex == 1) {
      this.firstStepSubmit = true;
      if (this.formGroup.controls['name_ar'].valid && this.formGroup.controls['name_en'].valid) {
        this.currentStepIndex = this.currentStepIndex + 1;
        this.value = ((this.currentStepIndex) / this.bufferValue) * 100;
        this.getCategories();
      } else {
        this.formGroup.controls['name_ar'].markAsTouched();
        this.formGroup.controls['name_en'].markAsTouched();
      }
    }
    else if (this.currentStepIndex == 2) {
      this.secondStepSubmit = true;
      if (this.selectedCategories.length) {
        this.currentStepIndex = this.currentStepIndex + 1;
        this.value = ((this.currentStepIndex) / this.bufferValue) * 100;
        this.GetCities();
      }
    }
    else if (this.currentStepIndex == 3) {
      this.thirdStepSubmit = true;
      if (this.formGroup.controls['city_id'].valid) {
        this.currentStepIndex = this.currentStepIndex + 1;
        this.value = ((this.currentStepIndex) / this.bufferValue) * 100;
      } else {
        this.formGroup.controls['city_id'].markAsTouched();
      }
    }
    else if (this.currentStepIndex == 4) {
      this.fourthStepSubmit = true;
      if (this.formGroup.controls['license_number'].valid && this.formGroup.controls['tax_number'].valid) {
        this.currentStepIndex = this.currentStepIndex + 1;
        this.value = ((this.currentStepIndex) / this.bufferValue) * 100;
      } else {
        this.formGroup.controls['license_number'].markAsTouched();
        this.formGroup.controls['tax_number'].markAsTouched();
      }
      return;
    }
    else if (this.currentStepIndex == 5) {
      this.fifthStepSubmit = true;
      this.currentStepIndex = this.currentStepIndex + 1;
      this.value = ((this.currentStepIndex) / this.bufferValue) * 100;
      // else {
      //   this.fifthStepSubmit = true;
      // }
    }
    else if (this.currentStepIndex == 6) {
      if (
        this.formGroup.controls['full_name'].valid &&
        this.formGroup.controls['job_title'].valid &&
        this.formGroup.controls['phone_number'].valid &&
        this.formGroup.controls['email'].valid
      ) {
        this.currentStepIndex = this.currentStepIndex + 1;
        this.value = ((this.currentStepIndex) / this.bufferValue) * 100;
      } else {
        this.formGroup.controls['full_name'].markAsTouched() 
        this.formGroup.controls['job_title'].markAsTouched() 
        this.formGroup.controls['phone_number'].markAsTouched() 
        this.formGroup.controls['email'].markAsTouched()
      }
    }
  }

  // Submit
  submitRequest() {
    this.seventhStepSubmit = true;
    if (this.formGroup.valid) {
      this.loading = true;
      let model = { ...this.formGroup.value }
      model.phone_number = model.phone_number ? '5' + model.phone_number : model.phone_number
      // model.vendor_phone_number = model.vendor_phone_number ? '05' + model.vendor_phone_number : model.vendor_phone_number
      model.categories_ids = this.selectedCategories.map((x: any) => x.id);

      const formData = new FormData
      for (const key in model) {
        const element = model[key];
        if (Array.isArray(element)) {
          element.forEach(item => {
            formData.append(`${key}[]`, item)
          });
        } else {
          element ? formData.append(key, element) : null
        }
      }

      let html = '';
      html = `<p class="font-18 fontRegular grey-color mb-0">${this.translate.instant('vendor_register.Your request will be reviewed and responded to via the store account managers email')}</p> <br> <p class="font-18 fontRegular grey-color">${this.translate.instant('vendor_register.We may contact you if necessary')}</p>`;
      this.apiService.RegisterVendor(formData).subscribe((data) => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('vendor_register.Your application to join as a vendor has been submitted successfully'),
          text: '',
          html: html,
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        this.dialogRef.close(true);
      }, error => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('swalMsg.Failed'),
          text: error.errors[0].massage,
          icon: 'error',
          confirmButtonText: this.translate.instant('swalMsg.Ok'),
        })
      });
    } else {
      this.formGroup.markAllAsTouched()
    }
  }
}
