import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(name: string, length?: number): string {
    const biggestWord = 50;
    const elipses = "...";
    let shortName = '';

    if (!name) { return ''; }

    if (length && name.length <= length) {
      /* .. truncate to about correct lenght */
      shortName = name.slice(0, length + biggestWord);

      /* .. now nibble ends till correct length */
      while (shortName.length > length - elipses.length) {
        const lastSpace = shortName.lastIndexOf(" ");
        if (lastSpace === -1) break;
        shortName = shortName.slice(0, lastSpace).replace(/[!,.?;:]$/, '');
      }
      shortName += elipses;
    } else {
      const initials = name.split(' ') || [];
      if (initials[0]) {
        shortName += initials[0][0];
        // shortName += this.isRTL(name) ? ' ' : '';
      }
      // if (initials[(initials.length) - 1]) {
      //   shortName += initials[(initials.length) - 1][0];
      // } else if (initials[(initials.length) - 2]) {
      //   shortName += initials[(initials.length) - 2][0];
      // }
      shortName = shortName.toUpperCase();
    }
    return shortName;
  }

  isRTL(s: string) {
    const ltrChars = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' + '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
    const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
    const rtlDirCheck = new RegExp('^[^' + ltrChars + ']*[' + rtlChars + ']');
    return rtlDirCheck.test(s);
  }

}
