import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(public _translate: TranslateService,
    public _router: Router) { }


  goTo(url: string) {
    this._router.navigate([url])
  }

  getAuthUserInfo() {
    const authUser: any = localStorage.getItem("auth");
    return JSON.parse(authUser);
  }

  SusseccMessage(Title?: string, Text?: string, ButtonText?: string) {
    ButtonText = !ButtonText ? this._translate.instant('resetPassword.ok') : null;
    Swal.fire({
      title: Title,
      text: Text,
      // icon: 'success',
      imageUrl: "/assets/images/icons/success.svg",
      imageAlt: "Success icon",
      confirmButtonText: ButtonText,
      customClass: {
        title: "swal_title",
        confirmButton: "btn-primary"
      }

    }).then((result) => {
      return result;
    });
  }



  showErrors(error: any) {
    let text = ''
    error?.errors.forEach((e: any) => {
      text += `<p>${e.massage}</p>`;
    });
    Swal.fire({
      title: this._translate.instant('swalMsg.Failed'),
      html: text,
      icon: 'error',
      confirmButtonText: this._translate.instant('swalMsg.Ok'),
    })
  }


}
