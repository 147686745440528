import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-search-google-maps',
  templateUrl: './search-google-maps.component.html',
  styles: ``
})
export class SearchGoogleMapsComponent implements OnInit {
  @ViewChild('inputField')
  inputField!: ElementRef;

  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  autocomplete: google.maps.places.Autocomplete | undefined;

  listener: any;

  constructor(private ngZone: NgZone) { }

  ngOnInit() { }


  ngAfterViewInit() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField.nativeElement
    );

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete?.getPlace();
        console.log(place);
        const result: PlaceSearchResult = {
          address: this.inputField.nativeElement.value,
          name: place?.name,
          location: {
            lat: Number(place?.geometry?.location?.lat()),
            lng: Number(place?.geometry?.location?.lng())
          },
          imageUrl: this.getPhotoUrl(place),
          iconUrl: place?.icon,
        };
        this.placeChanged.emit(result);
      });
    });
  }

  getPhotoUrl(
    place: google.maps.places.PlaceResult | undefined
  ): string | undefined {
    return place?.photos && place?.photos.length > 0
      ? place?.photos[0].getUrl({ maxWidth: 500 })
      : undefined;
  }

  ngOnDestroy() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }
}


export interface PlaceSearchResult {
  address: string;
  location?: { lat: number, lng: number };
  imageUrl?: string;
  iconUrl?: string;
  name?: string;
}