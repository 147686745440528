import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DirtyComponent } from '../../helpers/CanDeactivate/dirty-component';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidator } from '../../helpers/custom.validator';
import { Patterns } from '../../helpers/patterns';
import { Subscription } from 'rxjs';
import { AuthAPIService } from '../../features/auth/services/auth-api.service';
import { MatStepper } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ConfigService } from '../../helpers/config.service';


@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styles: ``
})


export class CompanyRegisterComponent extends DirtyComponent implements OnInit {
  TotalSteps: number = 5;
  CurrentStep: number = 0
  isSubmited: boolean = false;
  packageIsSubmitted: boolean = false;
  override formGroup!: FormGroup;
  isEditable = false;

  loadingCities: boolean = false;
  CitesList: any[] = [];
  citesModal = { perPage: 160, page: 1, search: '' }
  citiesCount: number = 0;
  subscribtion: Subscription[] = []


  // Packages list Variables
  loadingPackages: boolean = false;
  PackagesList: any[] = [];
  packageModal = { perPage: 12, page: 1 }

  loadingSubmit: boolean = false;

  @ViewChild('stepper') stepper!: MatStepper;

  constructor(private _formBuilder: FormBuilder,
    private _Authservice: AuthAPIService,
    override dialogRef: MatDialogRef<CompanyRegisterComponent>,
    private _config: ConfigService,
    translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { packageId: string }
  ) {
    super(translate)
  }

  ngOnInit(): void {

    this.formGroup = this._formBuilder.group({
      companyName: this._formBuilder.group({
        company_image: [null],
        name_ar: [null, [Validators.required, Validators.pattern(Patterns.arTxtWithSpecial), CustomValidator.noWhiteSpace, Validators.maxLength(100)]],
        name_en: [null, [Validators.required, Validators.pattern(Patterns.enTxtWithSpecial), CustomValidator.noWhiteSpace, Validators.maxLength(100)]]
      }),
      company_info: this._formBuilder.group({
        city_id: [null, [Validators.required]],
        address_ar: [null, [Validators.pattern(Patterns.arTxtWithSpecial), Validators.maxLength(100)]],
        address_en: [null, [Validators.pattern(Patterns.enTxtWithSpecial), Validators.maxLength(100)]],
        company_phone_number: [null, [Validators.maxLength(10), Validators.minLength(8)]],
        company_email: [null, [Validators.maxLength(100), Validators.pattern(Patterns.email)]],
      }),
      company_package: this._formBuilder.group({
        package_id: [this.dialogData.packageId, [Validators.required]]
      }),
      company_files: this._formBuilder.group({
        license_number: [null, [CustomValidator.noWhiteSpace, Validators.required, Validators.maxLength(20)]],
        license_attachment: [null],
        tax_number: [null, [CustomValidator.noWhiteSpace, Validators.required, Validators.maxLength(20)]],
        tax_attachment: [null],
      }),
      company_admin: this._formBuilder.group({
        full_name: [null, [CustomValidator.noWhiteSpace, Validators.required, Validators.maxLength(100)]],
        job_title: [null, [CustomValidator.noWhiteSpace, Validators.required, Validators.maxLength(100)]],
        phone_number: [null, [CustomValidator.noWhiteSpace, Validators.required, Validators.maxLength(10)]],
        email: [null, [CustomValidator.noWhiteSpace, Validators.required, Validators.maxLength(100), Validators.pattern(Patterns.email)]]
      })
    })

  }

  onSelectChange(event: any) {
    this.CurrentStep = event;

    if (this.CurrentStep == 2 && !this.PackagesList.length) {
      this.GetPackages();
    } else if (this.CurrentStep == 1 && !this.CitesList.length) {
      this.GetCities();
    }
  }


  hasError(formName: string, controlName: string, errorName: string) {
    if (controlName === 'city_id') {
      return this.getFormGroup(formName).controls[controlName].hasError(errorName) && (this.getFormGroup(formName).controls[controlName].touched || this.isSubmited)
    }

    if (controlName === 'package_id') {
      return this.getFormGroup(formName).controls[controlName].hasError(errorName) && (this.getFormGroup(formName).controls[controlName].touched || this.packageIsSubmitted)
    }

    return this.getFormGroup(formName).controls[controlName].hasError(errorName) && this.getFormGroup(formName).controls[controlName].invalid;
  }


  getFormGroup(formgroupName: string) {
    return this.formGroup.get([formgroupName]) as FormGroup;
  }


  OnSelectProfileImage(profileImage: any) {
    this.getFormGroup('companyName').patchValue({ company_image: profileImage });
    this.getFormGroup('companyName').controls['company_image'].markAsDirty();
  }
  OnSelectLicense_attachment(files: any) {
    this.getFormGroup('company_files').patchValue({ license_attachment: files[0] });
    this.getFormGroup('company_files').controls['license_attachment'].markAsDirty();

  }
  OnSelectTax_attachment(files: any) {
    this.getFormGroup('company_files').patchValue({ tax_attachment: files[0] });
    this.getFormGroup('company_files').controls['tax_attachment'].markAsDirty();

  }

  GetCities() {
    this.subscribtion.forEach(element => {
      element.unsubscribe();
    });
    this.loadingCities = true;
    this.subscribtion.push(this._Authservice.GetCities(this.citesModal).subscribe({
      next: (res: any) => {
        this.loadingCities = false;
        this.CitesList = this.CitesList.concat(res.data.list);
        this.citiesCount = res.data.total;
      },
      error: (error: any) => {
        this.loadingCities = false;
      }
    }))
  }

  // City with Scroll

  dropSearch(data: any) {
    this.CitesList = [];
    this.citesModal.search = data?.term || '';
    this.GetCities();
  }

  OnClear() {
    this.formGroup.patchValue({ city_id: null })
    this.citesModal.search = ''
    this.CitesList = [];
    this.GetCities();
  }

  GetPackages() {
    this.loadingPackages = true;
    this._Authservice.GetPackagesWithoutPermission().subscribe({
      next: (res: any) => {
        this.loadingPackages = false;
        this.PackagesList = res.data.list;
        this.formGroup.patchValue({ package_id: this.PackagesList[0].id })
      },
      error: (error: any) => {
        this.loadingPackages = false;
      }
    })
  }


  GoNext() {
    if (this.CurrentStep == 1) {
      this.isSubmited = true;
    }
    if (this.CurrentStep == 2) {
      this.packageIsSubmitted = true;
    }
    this.stepper.next()
  }


  OnSubmit() {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      this.loadingSubmit = true;
      let model: any = this.MergeObjects(this.formGroup.value)
      console.log(model);
      // if (model.company_phone_number?.length == 8) {
      //   model.company_phone_number = '05' + model.company_phone_number
      // }
      if (model.phone_number?.length == 8) {
        model.phone_number = '5' + model.phone_number
      }
      const formData = new FormData();
      for (const key in model) {
        const element = model[key];
        if (element) {
          formData.append(key, element);
        }
      }

      this._Authservice.RegisterCompany(formData).subscribe({
        next: (res: any) => {
          this.loadingSubmit = false;
          this._config.SusseccMessage(this._config._translate.instant("company_register.Your application for your participation as a company has been submitted successfully"),
            this._config._translate.instant("company_register.Your request will be reviewed and responded to through the e-mail of the store account manager (you can be contacted by us if required)"))
          this.dialogRef.close();
        },
        error: (error: any) => {
          this.loadingSubmit = false;

        }
      })

    }
  }

  ToNumber(num: string) {
    return parseInt(num)
  }

  MergeObjects(obj: any) {
    let temp: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const obj2 = obj[key];
        for (const key2 in obj2) {
          if (obj2.hasOwnProperty(key2)) {
            temp[key2] = obj2[key2];
          }
        }
      }
    }
    return temp;
  }
}
