import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { ConfigService } from '../../helpers/config.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
})
export class FileManagerComponent implements OnInit {
  @Output() selectedFiles = new EventEmitter<[]>();
  @Output() deletedFilesList = new EventEmitter<[]>();

  @Input() files: any = [];
  @Input() docsEditable = true;
  @Input() maxFiles = 5;
  @Input() maxFileSize = 10;
  @Input() managerIndex: any = '0'; // For input id multi file manager in one view
  @Input() label!: string;
  @Input() isRequired = false;
  @Input() isSubmitted = false;
  @Input() isDisabled = false;
  @Input() cols = 1;
  @Input() mode: "inline" | "box" | "inline-profile" = "inline";

  @Input() allowedExt: any[] = [
    'pdf',
    'jpeg',
    'jpg',
    'png',
    'doc',
    'docx',
    'wpd',
    'tex',
    'ppt',
    'pptx',
    'xlsx',
    'xls',
    'zip',
    'rar',
  ];

  @Input() renderBtn = false;
  // Uploader
  @Input() isUploader = false;
  @Input() uplodaerType = 0;
  loading = false;
  uploadedFilesArr: any = [];

  /////
  selectedfilesCount = 0;
  deletedFiles: any = [];
  validFile = true;
  fileMaxSiZeErorr = false;
  fileMaxNameSiZeErorr = false;
  AcceptedExtentions!: string;

  constructor(private _config: ConfigService) { }

  ngOnInit() {
    // this.files.map((file: any) => {
    //   file.name = file.filePath
    // });
    // if (this.files.length && this.isUploader) {
    //   this.files.forEach(file => {
    //     this.uploadedFilesArr.push({
    //       fileName: file.name,
    //       fileUpload: file.token
    //     });
    //   });
    //   this.selectedFiles.emit(this.uploadedFilesArr);
    // }
    if (this.allowedExt) {
      this.AcceptedExtentions = '.' + this.allowedExt.join(', .');
    }
  }

  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.allowedExt) {
      this.AcceptedExtentions = '.' + this.allowedExt.join(', .');
    }
  }

  validFileType(fileName: string) {
    const extension = fileName.split('.').pop()?.toLowerCase();
    const extensionUpper = fileName.split('.').pop()?.toUpperCase();

    if (!(this.allowedExt.includes(extension) || this.allowedExt.includes(extensionUpper))) {
      return false;
    }
    return true;
  }
  fileExt(fileName: string) {
    const extension = fileName?.split('.')?.pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
        return 'doc';
      case 'xlsx':
      case 'xls':
        return 'xls';
      case 'zip':
      case 'rar':
        return 'zip';
      case 'ppt':
      case 'pptx':
        return 'pptx';
      case 'mp4':
      case 'mpg':
        return 'video';
      default:
        return false;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.target.files);
    event.target.value = '';
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, id: any) {
    if (id) {
      this.deletedFiles.push(id);
    }

    const deleted = this.files[index];
    this.files.splice(index, 1);

    if (this.isUploader) {
      const deletedIndx = this.uploadedFilesArr.findIndex((item: any) => item.fileUpload === deleted.token);
      this.uploadedFilesArr.splice(deletedIndx, 1);
      this.selectedFiles.emit(this.uploadedFilesArr);
    } else {
      this.selectedFiles.emit(this.files);
      this.deletedFilesList.emit(this.deletedFiles);
    }
    this.selectedfilesCount = this.files.length;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    const filesToUpload = [];
    this.validFile = true;
    this.selectedfilesCount = this.files.length + files.length;
    if (this.selectedfilesCount > this.maxFiles) {
      this.renderBtn ? Swal.fire({
        title: this._config._translate.instant('swalMsg.Failed'),
        text: this.maxFiles === 1 ? this._config._translate.instant('fileManager.Only One File is allowed') :
          this._config._translate.instant('fileManager.Maximum allowed files is', { maxFiles: this.maxFiles }),
        icon: 'error',
        confirmButtonText: this._config._translate.instant('swalMsg.Ok'),
      }) : null;

      return;
    }
    for (const item of files) {
      if (this.validFileType(item.name)) {
        this.validFile = true;
      } else {
        this.validFile = false;
        this.renderBtn ? Swal.fire({
          title: this._config._translate.instant('swalMsg.Failed'),
          text: this._config._translate.instant("fileManager.Invalid File Extensions"),
          icon: 'error',
          confirmButtonText: this._config._translate.instant('swalMsg.Ok'),
        }) : null;
        return
      }

      // Check file and push to array files
      if (item.size / (1024 * 1024) <= this.maxFileSize && this.validFileType(item.name)) {
        item.fileType = this.fileExt(item.name);
        this.files.push(item);
        filesToUpload.push(item);

        if (this.isUploader) {
          this.uploadFile(filesToUpload);
        } else {
          this.selectedFiles.emit(this.files);
        }

        if (item.size / (1024 * 1024) <= this.maxFileSize) {
          this.fileMaxSiZeErorr = false;
        }
      } else {
        if (item.size / (1024 * 1024) > this.maxFileSize) {
          this.fileMaxSiZeErorr = true;
          this.renderBtn ? Swal.fire({
            title: this._config._translate.instant('swalMsg.Failed'),
            text: this._config._translate.instant("fileManager.Max File Size", { maxFileSize: this.maxFileSize }),
            icon: 'error',
            confirmButtonText: this._config._translate.instant('swalMsg.Ok'),
          }) : null;


        }
      }
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadFile(files: any) {
    this.loading = true;
    this.isDisabled = true;
    // this.service.upload_file(files, this.uplodaerType).subscribe(
    //   (response) => {
    //     this.loading = false;
    //     this.Disapled = false;
    //     this.uploadedFilesArr.push(response.data);
    //     this.selectedFiles.emit(this.uploadedFilesArr);
    //   },
    //   (err) => {
    //     this.loading = false;
    //     this.Disapled = false;
    //   }
    // );
  }
}
