import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ApiServiceService } from './api-service.service';
import { environment } from '../../environments/environment';
import { User } from '../models/auth/user';
import { ConfigService } from '../helpers/config.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  menuLoaded = new BehaviorSubject<any>(false);
  generalRefreshDetails = new BehaviorSubject<boolean>(false);
  public currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    // private http: HttpClient,
    private router: Router,
    private _config: ConfigService,
    private _APIservice: ApiServiceService
  ) {
    const authLS = localStorage.getItem('authUser');
    const ls = authLS ? JSON.parse(authLS) as User : '';
    if (ls) {
      this.currentUserSubject.next(ls);
    }
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  login(data: any): any {
    return this._APIservice.post(
      `${environment.apiUrl}/web/authentication/login`, data)
      .pipe(map(res => {
        let user = res.data.user;
        user.company = res.data.company
        if (user.id == "c7cdfd56-49dc-4be6-a305-145ccf9221db") {
          user.company.color = "#235789"
          user.company.font_color = "#F1D302"
        }
        user.company.color = hexToRgb(user?.company?.color)
        this.currentUserSubject.next(user);
        localStorage.setItem('authUser', JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        if (res.data?.first_login) {
          this._config.goTo(`/interests`)
        } else {
          this._config.goTo("/panel");
          let color = res.data.company?.color
          let text_color = res.data.company?.font_color
          document.documentElement.style.setProperty('--main-color', color);
          document.documentElement.style.setProperty('--main-text-color', text_color);
          console.info(`color:${color} , font_color: ${text_color}`)
        }

        // this.ConnectToSocket(model).subscribe((response) => {
        //   console.log("Clinet registered successfully", response);
        // })

        return res

      }), catchError(this.handleError));
  }

  logout() {
    this.clearUserData();
    this.router.navigate(['/login']);
    document.documentElement.style.setProperty('--main-color', '73, 214, 214');
    document.documentElement.style.setProperty('--main-text-color', "#123636");
  }

  clearUserData() {
    localStorage.removeItem('authUser');
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }

  handleError(error: any) {
    return throwError(error);
  }

  ConnectToSocket(model: any) {
    return this._APIservice.post(environment.apiUrl + '/web/real-time-register', model)
      .pipe(catchError(this.handleError));
  }




}
export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}` : null;

}