import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { ConfigService } from '../helpers/config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(
    public http: HttpClient,
    private _config: ConfigService
  ) { }

  get(path: string, params?: HttpParams): Observable<any> {
    return this.http
      .get(`${path}`, { headers: this.setHeaders(), params })
      .pipe(
        catchError((er) => this.formatErrors(er)),
        map((res: any) => res)
      );
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${path}`, body).pipe(
      catchError((er) => this.formatErrors(er)),
      map((res: any) => res)
    );
  }

  update(path: string, body: any = {}): Observable<any> {
    body._method = "PUT";
    return this.http.post(`${path}`, body, { headers: this.setHeaders() }).pipe(
      catchError((er) => this.formatErrors(er)),
      map((res: any) => res)
    );
  }


  remove(path: string): Observable<any> {
    return this.http.delete(`${path}`, { headers: this.setHeaders() }).pipe(
      catchError((er) => this.formatErrors(er)),
      map((res: any) => res)
    );
  }


  getDownload(
    path: string,
    body: object = {}
  ): Observable<any> {
    // params['responseType'] = 'arraybuffer';
    // alert(path);
    return this.http
      .post(`${path}`, body, {
        headers: this.setHeadersWithFiles(),
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((er) => this.formatErrors(er)),
        map((res: any) => res)
      );
  }


  downloadFile(path: any, model: any) {
    return this.getDownload(
      path,
      model
    )
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }

  private setHeadersWithFiles(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      responseType: 'blob',
    };
    return new HttpHeaders(headersConfig);
  }


  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    let text = ''
    error?.errors.forEach((e: any) => {
      text += `<p>${e.massage}</p>`;
    });
    if (error.status != 404) {
      Swal.fire({
        title: this._config._translate.instant('swalMsg.Failed'),
        html: text,
        icon: 'error',
        confirmButtonText: this._config._translate.instant('swalMsg.Ok'),
      })
    }

    return throwError(error);
  }

}
