import { Component, inject } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
})
export class CropperDialogComponent {
  data: {
    image: File;
    ratio: number;
  } = inject(MAT_DIALOG_DATA);
  result!: any;
  showCropper = false;
  scale = 1;
  transform: ImageTransform = {
    translateUnit: 'px'
  };

  constructor(
    private dialogRef: MatDialogRef<CropperDialogComponent>
  ) { }

  imageLoaded() {
    this.showCropper = true;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.result = event;
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  saveImage() {
    this.dialogRef.close(this.result);
  }
}
