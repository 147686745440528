import { Component, Directive, EventEmitter, HostListener } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-canDeactivate',
  template: ''
})

export abstract class DirtyComponent {
  form!: NgForm;
  formGroup!: FormGroup;
  leaveMsg!: string;
  dialogRef: any;
  close = new EventEmitter<boolean>();

  constructor(
    public translate: TranslateService
  ) { }

  canDeactivate(): boolean | Observable<boolean> {
    return this.formGroup?.dirty;
  };

  openConfirmDialog() {
    if (this.canDeactivate()) {
      return Swal.fire({
        title: this.translate.instant('swalMsg.Areyousure'),
        // title: this.translate.instant('swalMsg.Unsaved changes are detected'),
        text: this.leaveMsg || this.translate.instant('swalMsg.changesHappend'),
        // icon: 'warning',
        // iconHtml: '<img src="./assets/images/icons/warning-ic.svg" />',
        imageUrl: './assets/images/icons/warning-ic.svg',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('swalMsg.Yes'),
        cancelButtonText: this.translate.instant('swalMsg.No'),
        // width: 750,
        // customClass: {
        //   container: 'sweet-dialog',
        // }
      }).then((result) => {
        if (result.value) {
          this.dialogRef?.close();
          this.close.emit(true);
          return true;
        }
        else {
          return false;
        }
      });
    } else {
      this.close.emit(true);
      this.dialogRef?.close();
    }
    return false;
  }

  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    if (this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}
