<ng-container *ngIf="TREE_DATA.length">
    <mat-tree class="mat-tree-with-img" [dataSource]="dataSource" [treeControl]="treeControl"
        [ngClass]="{'tree-view': isView, 'has-error': hasError()}">

        <mat-tree-node class="has-parent" [ngClass]="'level-' + node.level" [style.display]="
        (isView && !node.is_checked)
        ||
        (searchText && hideLeafNode(node) && !showParentNode(node))
        ? 'none' : 'flex'" *matTreeNodeDef="let node; let i = index" matTreeNodeToggle matTreeNodePadding>

            <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                (change)="todoLeafItemSelectionToggle(node); selectedItem();">

                <div class="name-with-image mb-0">
                    <div class="image-square">
                        <img *ngIf="node.image" [src]="node.image" (error)='node.image = ""'>
                        <span *ngIf="!node.image">{{ node.name | shortName }}</span>
                    </div>
                    <div class="name">
                        <h5 class="font-18 fontRegular">{{node.name}}</h5>
                        <h5 class="font-18 fontRegular">{{node.offer_type?.name}}</h5>
                    </div>
                </div>
            </mat-checkbox>
        </mat-tree-node>

        <mat-tree-node class="parent justify-content-between" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
            [ngClass]="'level-' + node.level"
            [style.display]="hideParentNode(node) && hideLeafNode(node) ? 'none' : 'flex'"
            [class.active]="treeControl.isExpanded(node)">

            <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
                (change)="todoItemSelectionToggle(node); selectedItem();">
                <div class="name-with-image mb-0">
                    <div class="image-square">
                        <img *ngIf="node.logo" [src]="node.logo" (error)='node.logo = ""'>
                        <span *ngIf="!node.logo">{{ node.name | shortName }}</span>
                    </div>
                    <div class="name">
                        <h5 class="font-18 fontRegular mb-2">{{node.name}}</h5>
                        <h5 class="font-14 fontRegular">
                            {{getSelectedChildCount(node)}}<span class="grey-color">
                            {{ 'vendor_register.Subcategory selected from' | translate }} </span> {{node.child.length}}
                        </h5>
                    </div>
                </div>
            </mat-checkbox>


            <button mat-icon-button matTreeNodeToggle type="button">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_less' : 'expand_more'}}
                </mat-icon>
            </button>

        </mat-tree-node>

    </mat-tree>
</ng-container>
<p class="has-error m-2" *ngIf="hasError()">{{"forms.Required" | translate}}</p>
