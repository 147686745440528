import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidator {
  static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
    let crtValue = control.value;
    if (crtValue) { crtValue = crtValue.trim(); }
    if (!crtValue) { return { required: true }; }
    return null;
  }
  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }
}
