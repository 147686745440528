import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CropperDialogComponent } from './cropper-dialog/cropper-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-control',
  templateUrl: './image-control.component.html'
})
export class ImageControlComponent implements OnInit {

  @Input() ratio = 1 / 1;
  @Input() src!: any;
  @Output() imageReady = new EventEmitter<{ blob: Blob, src: string }>();

  @Input() maxSize = 2;
  @Output() errors = new EventEmitter<any>();
  fileErrors = { type: false, size: false };

  @Input() isDisabled = false;
  @Input() label!: string;
  @Input() managerIndex: any = '0'; // For input id multi file manager in one view
  @Input() allowedExt: any[] = ['png', 'jpg', 'jpeg'];
  @Input() isRequired: boolean = false;
  @Input() isSubmited: boolean = false;

  @Input() wideImage: boolean = false;
  @Input() wideSquareImage: boolean = false;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  fileSelected(event: any) {
    const file = event.target?.files[0];
    if (file && this.validateFile(file)) {
      const dialogRef = this.dialog.open(CropperDialogComponent, {
        data: { image: file, ratio: this.ratio },
        width: '600px',
      });
      dialogRef.afterClosed().subscribe((result: ImageCroppedEvent) => {
        if (result && result.blob) {
          this.src =
            this.sanitizer.bypassSecurityTrustUrl(result.objectUrl || result.base64 || '');
          let file: any = result.blob;
          file.name = 'image.png';
          this.imageReady.emit(file);
          // this.imageReady.emit({ blob: file, src: this.src });
        }
      });
    }
    this.errors.emit(this.fileErrors);
  }

  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileErrors.type = false;
      if ((file.size / (1024 * 1024)) <= this.maxSize) {
        this.fileErrors.size = false;
      } else {
        this.fileErrors.size = true;
        return false;
      }
    } else {
      this.fileErrors.type = true;
      return false;
    }
    return true;
  }
  fileType(fileName: string): any {
    const extension = fileName.split('.').pop()?.toLowerCase();
    const extensionUpper = fileName.split('.').pop()?.toUpperCase();
    if (!(this.allowedExt.includes(extension) || this.allowedExt.includes(extensionUpper))) {
      return false;
    }
    return true;
  }
}
