<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{ "vendor_register.Apply as a vendor" | translate }}
    </div>

    <form [formGroup]="formGroup">
        <div class="mat-dialog-content dialog_content p-0 register-vendor">
            <div class="answers-paging p-0">
                <div class="paging">
                    <p> <span>{{currentStepIndex}}</span>
                        {{'forms.of' | translate}}
                        {{bufferValue}}
                    </p>
                </div>
                <mat-progress-bar class="example-margin" [color]="color" [mode]="mode" [value]="value">
                </mat-progress-bar>
            </div>

            <div *ngIf="currentStepIndex == 1">
                <div class="dialog-padding">
                    <div class="font-22 fontMedium mb-4">
                        {{ 'vendor_register.Basic data of the vendor' | translate }}
                    </div>
                    <div class="fields-group mb-3">
                        <app-image-control [label]="'vendor_register.Vendor Service Provider logo' | translate"
                            [managerIndex]="'profile_image'" [ratio]="500 / 500" [maxSize]="2"
                            (imageReady)="OnSelectLogo($event)" [isRequired]="false" [isSubmited]="firstStepSubmit">
                        </app-image-control>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="arabic-form-field">
                            <mat-label>اسم المتجر (باللغة العربية)</mat-label>
                            <input matInput maxlength="100" formControlName="name_ar">
                            <mat-error *ngIf="hasError('name_ar','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('name_ar', 'pattern')">
                                {{ 'forms.Enter name (in Arabic)' | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="english-form-field">
                            <mat-label>Vendor's name (in English)</mat-label>
                            <input matInput maxlength="100" formControlName="name_en">
                            <mat-error *ngIf="hasError('name_en','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('name_en', 'pattern')">
                                {{ 'forms.Enter name (in English)' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-slide-toggle formControlName="have_online_store" class="mb-3">
                            {{ 'vendor_register.You have an online store' | translate}}
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>

            <div *ngIf="currentStepIndex == 2">
                <div class="search">
                    <div class="search_input font-14">
                        <a>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.997" viewBox="0 0 16 15.997">
                                <path id="search"
                                    d="M17.754,16.627l-2.966-2.944A7.194,7.194,0,1,0,13.677,14.8l2.942,2.944a.795.795,0,0,0,1.135-1.112ZM9.192,14.8a5.6,5.6,0,1,1,5.6-5.6,5.6,5.6,0,0,1-5.6,5.6Z"
                                    transform="translate(-1.979 -1.979)" fill="#666" />
                            </svg>
                        </a>
                        <input type="text" [placeholder]="'vendor_register.Search for a vendor category'|translate"
                            [(ngModel)]="categoriesModel.search" [ngModelOptions]="{standalone:true}"
                            (keyup.enter)="getCategories()">
                        <ng-container *ngIf="categoriesModel.search">
                            <a (click)="categoriesModel.search = ''; getCategories()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.997" viewBox="0 0 14 14">
                                    <path id="ic_close"
                                        d="M211.746-746.861l-5.01,5.006a1.135,1.135,0,0,1-.827.356,1.094,1.094,0,0,1-.814-.356,1.1,1.1,0,0,1-.35-.819,1.127,1.127,0,0,1,.35-.807l5.01-5.018-5.01-5.018a1.1,1.1,0,0,1-.343-.807,1.145,1.145,0,0,1,.343-.819,1.093,1.093,0,0,1,.814-.356,1.155,1.155,0,0,1,.827.343l5.01,5.018,5.023-5.018a1.113,1.113,0,0,1,.814-.343,1.134,1.134,0,0,1,.827.356,1.122,1.122,0,0,1,.337.819,1.127,1.127,0,0,1-.35.807l-5.01,5.018,5.01,5.018a1.1,1.1,0,0,1,.343.807,1.146,1.146,0,0,1-.343.819,1.094,1.094,0,0,1-.814.356,1.094,1.094,0,0,1-.814-.356Z"
                                        transform="translate(-204.746 755.5)" fill="#6b6d85" />
                                </svg>
                            </a>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf='categoriesLoading' class="dataLoading py-5 d-block m-auto">
                    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
                </div>
                <div class="position-relative" *ngIf="!categoriesLoading">
                    <div class="dialog-padding">
                        <div class="font-22 fontMedium mb-3 mt-3">
                            {{"vendor_register.Vendor Category (Service Type)"|translate}}
                        </div>
                    </div>
                    <app-tree-selection-with-img [TREE_DATA]="categoriesList"
                        (selectedItems)="selectedCategories = $event" [selected]="selectedCategories"
                        [isRequired]="true" [isSubmitted]="secondStepSubmit"></app-tree-selection-with-img>
                </div>
            </div>

            <div *ngIf="currentStepIndex == 3">
                <div class="dialog-padding">
                    <div class="title">{{"vendor_register.Vendor Address"|translate}}</div>
                    <div class="fields-group mb-3">
                        <div class="ng-select-field">
                            <ng-select ngSelectMat appearance="fill"
                                [class.ng-invalid]="hasError('city_id','required') && thirdStepSubmit"
                                formControlName="city_id" [items]="CitesList" [virtualScroll]="true"
                                [loading]="loadingCities" bindLabel="name" bindValue="id"
                                [placeholder]='"companies.The City" | translate'
                                [notFoundText]='"general.No Data Found" | translate'
                                [loadingText]='"forms.Loading..." | translate' (search)="dropSearch($event)"
                                (clear)="OnClear()" dropdownPosition="bottom" [clearable]="true">
                                <!-- <ng-template ng-header-tmp *ngIf="CitesList?.length && !loadingCities">
                                    <small class="form-text text-muted">{{'forms.Loaded' | translate}}
                                        {{CitesList.length}} {{'forms.of' | translate}} {{citiesCount}}</small>
                                </ng-template> -->
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    {{item.name}}
                                </ng-template>
                            </ng-select>
                            <mat-error class="has-error" *ngIf="hasError('city_id','required') && thirdStepSubmit">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </div>
                    </div>
                    <div class="fields-group mb-3">
                        <!-- <mat-form-field class="with-suffix-padding">
                            <mat-label>{{ "vendor_register.Insert Location" | translate }}</mat-label>
                            <input matInput [(ngModel)]="SearchMap" [ngModelOptions]="{standalone:true}">
                            <span matSuffix>
                                <svg id="Trailing_Icons" data-name="Trailing Icons" xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" viewBox="0 0 24 24">
                                    <g id="search">
                                        <rect id="container" width="24" height="24" fill="none" />
                                        <path id="search_FILL0_wght400_GRAD0_opsz24"
                                            d="M136.6-822l-6.3-6.3a5.96,5.96,0,0,1-1.725.95,6.1,6.1,0,0,1-2.075.35,6.274,6.274,0,0,1-4.613-1.888A6.274,6.274,0,0,1,120-833.5a6.274,6.274,0,0,1,1.887-4.612A6.274,6.274,0,0,1,126.5-840a6.274,6.274,0,0,1,4.613,1.888A6.274,6.274,0,0,1,133-833.5a6.1,6.1,0,0,1-.35,2.075,5.959,5.959,0,0,1-.95,1.725l6.3,6.3Zm-10.1-7a4.34,4.34,0,0,0,3.188-1.312A4.34,4.34,0,0,0,131-833.5a4.34,4.34,0,0,0-1.312-3.187A4.34,4.34,0,0,0,126.5-838a4.34,4.34,0,0,0-3.187,1.313A4.34,4.34,0,0,0,122-833.5a4.34,4.34,0,0,0,1.313,3.188A4.34,4.34,0,0,0,126.5-829Z"
                                            transform="translate(-117 843)" fill="#999aab" stroke="rgba(0,0,0,0)"
                                            stroke-miterlimit="10" stroke-width="1" />
                                    </g>
                                </svg>

                            </span>
                        </mat-form-field> -->
                        <app-search-google-maps (placeChanged)="OnSelectFromSearch($event)"></app-search-google-maps>

                    </div>
                    <!-- Map Will be here -->
                    <div class="mb-3">
                        <google-map height="250px" width="100%" [options]="mapOptions" [center]="MapCenter"
                            [zoom]="MapZoom" (mapClick)="moveMap($event)">
                            <map-marker *ngIf="marker" [options]="marker"></map-marker>
                        </google-map>
                    </div>

                    <div class="fields-group mb-3">
                        <mat-form-field class="arabic-form-field">
                            <mat-label>العنوان التفصيلي (باللغة العربية)</mat-label>
                            <textarea matInput rows="5" maxlength="100" formControlName="address_ar"></textarea>
                            <mat-hint>{{"general.optionalAR"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('address_ar', 'pattern')">
                                {{ 'forms.Enter address (in Arabic)' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="english-form-field">
                            <mat-label>Street Address (in English)</mat-label>
                            <textarea matInput rows="5" maxlength="100" formControlName="address_en"></textarea>
                            <mat-hint>{{"general.optionalEn"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('address_en', 'pattern')">
                                {{ 'forms.Enter address (in English)' |translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div *ngIf="currentStepIndex == 4">
                <div class="dialog-padding">
                    <div class="title">{{"companies.Commercial and tax registration number"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"companies.Commercial registration No."|translate}}</mat-label>
                            <input matInput type="text" appOnlynumber maxlength="20" formControlName="license_number">
                            <mat-error *ngIf="hasError('license_number','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <app-file-manager [maxFiles]="1" [allowedExt]="['jpg', 'png','jpeg','pdf']" [maxFileSize]="2"
                            [files]="data?.license_file_object?[data?.license_file_object]:[]"
                            (selectedFiles)="OnSelectLicense_attachment($event)" managerIndex="license_attachment"
                            [label]="'companies.cerificate of Commercial registry document'|translate"></app-file-manager>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"companies.Tax Number"|translate}}</mat-label>
                            <input matInput type="text" appOnlynumber maxlength="20" formControlName="tax_number">
                            <mat-error *ngIf="hasError('tax_number','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <app-file-manager [maxFiles]="1" [allowedExt]="['jpg', 'png','jpeg','pdf']" [maxFileSize]="2"
                            [files]="data?.tax_number_object?[data?.tax_number_object]:[]"
                            (selectedFiles)="OnSelectTax_attachment($event)" managerIndex="tax_attachment"
                            [label]="'companies.Registrartion Document of added VAT System'|translate"></app-file-manager>
                    </div>
                </div>
            </div>

            <div *ngIf="currentStepIndex == 5">
                <div class="dialog-padding">
                    <div class="title">{{"companies.Contact Person"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="phoneNumberleft">
                            <mat-label>{{ "general.Mobile Number" | translate }}</mat-label>
                            <input matInput type="text" appOnlynumber maxlength="10" minlength="8"
                                formControlName="vendor_phone_number">
                            <span matSuffix>+966 </span>
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('vendor_phone_number','minlength')">
                                {{"form_message.at least characters"|translate :{num:8} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"forgetPassword.Email"|translate}}</mat-label>
                            <input matInput type="email" maxlength="100" formControlName="vendor_email">
                            <mat-error *ngIf="hasError('vendor_email','pattern')">
                                {{"form_message.Invalid email"|translate}}
                            </mat-error>
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>

                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{"vendor_register.website"|translate}}</mat-label>
                            <input matInput type="text" maxlength="50" formControlName="website">
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('website','pattern')">
                                {{"forms.Invalid link"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="title">{{"vendor_register.Social Media Channels"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{"vendor_register.Facebook"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="facebook_link">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/facebook.svg"
                                    alt="facebook Icon"></span>
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <!-- <mat-error *ngIf="hasError('facebook_link','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error> -->
                            <mat-error *ngIf="hasError('facebook_link','pattern')">
                                {{"forms.Invalid link"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{"vendor_register.Instagram"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="instagram_link">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/instagram.png"
                                    alt="instegram Icon"></span>
                            <!-- <mat-error *ngIf="hasError('instagram_link','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error> -->
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('instagram_link','pattern')">
                                {{"forms.Invalid link"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{"vendor_register.X"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="x_link">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/twitter.svg"
                                    alt="twitter Icon"></span>
                            <!-- <mat-error *ngIf="hasError('x_link','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error> -->
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('x_link','pattern')">
                                {{"forms.Invalid link"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{"vendor_register.whatsApp"|translate}}</mat-label>
                            <input matInput appOnlynumber type="text" maxlength="10" minlength="8"
                                formControlName="whatsapp">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/whatsApp.png"
                                    alt="twitter Icon"></span>
                            <!-- <mat-error *ngIf="hasError('x_link','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error> -->
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('whatsapp','minlength')">
                                {{"form_message.at least characters"|translate :{num:8} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{ "vendor_register.snapchat" | translate }}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="snapchat_link">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/snapchat-logo.svg"
                                    alt="Snapchat Icon"></span>
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('snapchat_link','minlength')">
                                {{"form_message.at least characters"|translate :{num:8} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field class="social_input">
                            <mat-label>{{ "vendor_register.tiktok" | translate }}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="tiktok_link">
                            <span matPrefix class="mr-3"><img src="/assets/images/icons/tiktok-app-icon.png"
                                    alt="Tiktok Icon"></span>
                            <mat-hint>{{"general.optional"|translate}}</mat-hint>
                            <mat-error *ngIf="hasError('tiktok_link','minlength')">
                                {{"form_message.at least characters"|translate :{num:8} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div *ngIf="currentStepIndex == 6">
                <div class="dialog-padding">
                    <div class="title">{{"vendor_register.Vendor Admin Data"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"general.Full Name"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="full_name">
                            <mat-error *ngIf="hasError('full_name','required') ">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label> {{"vendor_register.Job Title"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="job_title">
                            <mat-error *ngIf="hasError('job_title','required') ">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="fields-group mb-3">
                        <mat-form-field class="phoneNumberleft">
                            <mat-label> {{"general.Mobile Number"|translate}}</mat-label>
                            <input matInput type="text" maxlength="8" minlength="8" appOnlynumber
                                formControlName="phone_number">
                            <span matSuffix>+966 <b>5</b></span>
                            <mat-error *ngIf="hasError('phone_number','required') ">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('phone_number','minlength') ">
                                {{"form_message.at least characters"|translate :{num:8} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label> {{"forgetPassword.Email"|translate}}</mat-label>
                            <input matInput type="email" maxlength="100" formControlName="email">
                            <mat-error *ngIf="hasError('email','required') ">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('email','pattern') ">
                                {{"form_message.Invalid email"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div *ngIf="currentStepIndex == 7">
                <div class="dialog-padding">
                    <!-- BANK -->
                    <div class="title">{{"vendor_register.Bank Account Data"|translate}}</div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"vendor_register.bank_name"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="bank_name">
                            <mat-error *ngIf="hasError('bank_name','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"vendor_register.bank_address"|translate}}</mat-label>
                            <input matInput type="text" maxlength="300" formControlName="bank_address">
                            <mat-error *ngIf="hasError('bank_address','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"vendor_register.bank_account_name"|translate}}</mat-label>
                            <input matInput type="text" maxlength="100" formControlName="bank_account_name">
                            <mat-error *ngIf="hasError('bank_account_name','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"vendor_register.bank_account_number"|translate}}</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="bank_account_number">
                            <mat-error *ngIf="hasError('bank_account_number','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"vendor_register.iban"|translate}}</mat-label>
                            <input matInput type="text" maxlength="24" formControlName="iban">
                            <mat-error *ngIf="hasError('iban','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="fields-group mb-3">
                        <mat-form-field>
                            <mat-label>{{"vendor_register.swift_code"|translate}}</mat-label>
                            <input matInput type="text" maxlength="15" formControlName="swift_code">
                            <mat-error *ngIf="hasError('swift_code','required')">
                                {{"form_message.Required"|translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog_actions d-flex justify-content-between" mat-dialog-actions>
            <ng-container *ngIf="currentStepIndex == 1">
                <button mat-button type="button" class="btn-secondary" [disabled]="loading"
                    (click)="openConfirmDialog()">{{"general.Cancel"|translate}}
                </button>
            </ng-container>

            <ng-container *ngIf="currentStepIndex != 1">
                <button mat-button type="button" [disabled]="loading" class="btn-secondary"
                    (click)="backStepper()">{{"companies.back"|translate}}
                </button>
            </ng-container>

            <ng-container *ngIf="currentStepIndex != 7">
                <button mat-button type="button" class="btn-primary" (click)="nextStepper(currentStepIndex)">
                    {{"companies.next" | translate}}
                </button>
            </ng-container>

            <ng-container *ngIf="currentStepIndex == 7">
                <button mat-button type="button" [disabled]="loading" [class.loading]="loading" class="btn-primary"
                    (click)="submitRequest()">
                    {{"companies.submit application" | translate}}
                </button>
            </ng-container>
        </div>
    </form>
</div>