<div [ngClass]="{ active: docsEditable }" class="files-preview-container">
    <ng-container *ngIf="renderBtn">
        <label class="attach-btn" [attr.for]="'fileDropRef_' + managerIndex" mat-icon-button>
           <ng-content #lable></ng-content>
        </label>
        <input #fileDropRef (change)="fileBrowseHandler($event)" [attr.id]="'fileDropRef_' + managerIndex"
            [disabled]="isDisabled" [hidden]="true"
            [accept]="AcceptedExtentions" multiple
            type="file" />
    </ng-container>



    <ng-container *ngIf="!renderBtn">
        <div class="file-input" *ngIf="mode=='box'">
            <div class="header">
                <div class="title">
                    {{label}}
                </div>
                <span *ngIf="!isRequired">({{"fileManager.optional"|translate}})</span>
            </div>
            <div (fileDropped)="onFileDropped($event)" [class.loading]="loading" [hidden]="!docsEditable" appDnd
                class="dnd-container">
                <!-- accept="image/png, image/jpeg, application/pdf, .doc, .docx, .xlsx, .xls, .zip, .rar , .ppt, .pptx" -->
                <input #fileDropRef (change)="fileBrowseHandler($event)" [attr.id]="'fileDropRef_' + managerIndex"
                    [disabled]="isDisabled" multiple type="file" />
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="25" viewBox="0 0 19 25">
                        <path id="upload-icon"
                            d="M2.336,25A2.322,2.322,0,0,1,0,22.7V6.956H4.725a2.321,2.321,0,0,0,2.335-2.3V0h9.605A2.317,2.317,0,0,1,19,2.3V22.7A2.317,2.317,0,0,1,16.665,25Zm6.421-5.439a.743.743,0,0,0,1.486,0V14.269l1.916,1.887a.75.75,0,0,0,1.051,0,.723.723,0,0,0,0-1.035l-3.185-3.138a.749.749,0,0,0-1.05,0L5.791,15.12a.725.725,0,0,0,0,1.035.75.75,0,0,0,1.051,0l1.915-1.887ZM5.573,9.362a.738.738,0,0,0,.743.732h6.368a.732.732,0,1,0,0-1.465H6.316A.738.738,0,0,0,5.573,9.362ZM0,5.491V5.439a.729.729,0,0,1,.219-.518L5,.215A.747.747,0,0,1,5.52,0h.054V4.654a.844.844,0,0,1-.849.837Z"
                            fill="#49d6d6" />
                    </svg>

                </div>
                <h4>{{'fileManager.Drag files and drop here' | translate }}</h4>
                <div>
                    <span class="or">{{ 'fileManager.Or' | translate }}</span>
                    <label [attr.for]="'fileDropRef_' + managerIndex">

                        {{"fileManager.Upload/Select File" | translate}}</label>
                </div>
                <div class="hint">
                    {{"fileManager.Supported files:" | translate}}
                    <ng-container *ngIf="allowedExt.length < 14">
                        <!-- <span>{{allowedExt}}</span> -->
                        <span *ngFor="let item of allowedExt; index as i" class="text-uppercase">{{( i != 0 ? ', ' : '')
                            +
                            item }}</span>
                    </ng-container>
                    <span *ngIf="allowedExt.length >= 14">PDF, DOC, JPG and PNG</span>
                </div>


                <div class="hint">
                    <span class="d-block mt-2">
                        {{ "fileManager.Max File Size" | translate: { maxFileSize: maxFileSize } }}
                    </span>
                </div>
            </div>

        </div>

        <div class="file-input" *ngIf="mode == 'inline'">
            <input #fileDropRef (change)="fileBrowseHandler($event)" [hidden]="true"
                [attr.id]="'fileDropRef_' + managerIndex" [disabled]="isDisabled" [multiple]="false" type="file" />
            <div class="header">
                <div class="title">
                    {{label}}
                </div>
                <span *ngIf="!isRequired">({{"fileManager.optional"|translate}})</span>
            </div>
            <div class="hint inline">
                <span class="mt-2">({{ 'general.MB max' | translate:{no:maxFileSize} }})</span>
                <ng-container *ngIf="allowedExt.length < 14">
                    <span *ngFor="let item of allowedExt; index as i" class="text-uppercase">
                        {{ (i != 0 ? ', ' : '') + item }}</span>
                </ng-container>
                <span *ngIf="allowedExt.length == 14">PDF, DOC, JPG and PNG</span>
            </div>
            <label class="inline" [attr.for]="'fileDropRef_' + managerIndex">{{"fileManager.Upload/Select File" |
                translate}}</label>
        </div>

        <div class="file-input" *ngIf="mode == 'inline-profile'">
            <div class="image-uploader">
                <div class="upload-container">
                    <label [attr.for]="'fileDropRef_' + managerIndex">
                        <input #fileDropRef (change)="fileBrowseHandler($event)" [hidden]="true"
                            [attr.id]="'fileDropRef_' + managerIndex" [disabled]="isDisabled" [multiple]="false"
                            type="file" />
                        <div class="image-preview photo camera-fill">
                            <img>
                        </div>
                    </label>
                </div>
                <div>
                    <label>{{label}}</label>
                    <p>
                        {{ 'general.JPG or PNG' | translate }}
                        <span>({{ 'general.MB max' | translate:{no:5} }})</span>
                    </p>
                    <label [attr.for]="'fileDropRef_' + managerIndex">
                        <a>{{'general.Upload/Select File' | translate }}</a>
                    </label>

                </div>
            </div>
        </div>

        <!-- <div class="hint-listing">
        <div [ngClass]="{'has-error': selectedfilesCount > maxFiles}">{{ 'forms.Max-files-is' | translate }} {{maxFiles}}</div>
        <!- - <div [ngClass]="{'has-error': !validFile}">{{ 'forms.InvalidFile' | translate }}</div> - ->
        <div [ngClass]="{'has-error': !validFile}">{{ 'forms.AcceptableFiles' | translate }}</div>
        <div [ngClass]="{'has-error': fileMaxSiZeErorr}">{{ 'forms.Max-file-size' | translate }}</div>
        <div [ngClass]="{'has-error': !files.length && isRequired && isSubmitted}">{{ 'forms.Required' | translate }}</div>
    </div> -->
        <div *ngIf="selectedfilesCount > maxFiles && maxFiles > 1" class="has-error">
            {{ 'fileManager.Maximum allowed files is' | translate: { maxFiles: maxFiles } }}
        </div>
        <div *ngIf="selectedfilesCount > maxFiles && maxFiles === 1" class="has-error">
            {{ 'fileManager.Only One File is allowed' | translate }}
        </div>
        <div *ngIf="!validFile" class="has-error">{{"fileManager.Invalid File Extensions" | translate}}</div>
        <div *ngIf="fileMaxSiZeErorr" class="has-error">{{ "fileManager.Max File Size" | translate: { maxFileSize:
            maxFileSize } }}</div>
        <div *ngIf="fileMaxNameSiZeErorr" class="has-error"></div>
        <div *ngIf="!files.length && isRequired && isSubmitted" class="has-error">
            {{"forms.Required" | translate}}
        </div>
        <div *ngIf="!files.length" [hidden]="docsEditable" class="dnd-container">
            <h4>{{"fileManager.No Files" | translate}}</h4>
        </div>
        <div class="files-list" *ngIf="!renderBtn">
            <div class="row">
                <div *ngFor="let file of files; let i = index" [ngClass]="{ 'col-md-6': cols == 2 }" class="col-12">
                    <div *ngIf="file" class="file-item">
                        <div class="file-details">
                            <div class="file-icon">
                                <img *ngIf="fileExt(file.name) == 'doc'"
                                    src="./assets/images/file-icons/file-doc-icon.svg" />
                                <img *ngIf="fileExt(file.name) == 'jpg'"
                                    src="./assets/images/file-icons/file-jpg-icon.svg" />
                                <img *ngIf="fileExt(file.name) == 'pdf'"
                                    src="./assets/images/file-icons/file-pdf-icon.svg" />
                                <img *ngIf="fileExt(file.name) == 'png'"
                                    src="./assets/images/file-icons/file-png-icon.svg" />
                                <img *ngIf="fileExt(file.name) == 'pptx'"
                                    src="./assets/images/file-icons/file-ppt-icon.svg" />
                                <img *ngIf="fileExt(file.name) == 'xls'"
                                    src="./assets/images/file-icons/file-xls-icon.svg" />

                                <img *ngIf="fileExt(file.name) == 'zip'"
                                    src="./assets/images/file-icons/file-zip-icon.svg" />
                                <img *ngIf="fileExt(file.name) == 'video'"
                                    src="./assets/images/file-icons/file-video-icon.svg" />

                            </div>
                            <div>
                                <label [title]="file?.name">{{ file?.name }}</label>
                                <span>{{ formatBytes(file?.size) }}</span>
                            </div>
                        </div>
                        <div class="file-btn">
                            <!-- <a [download]="file.path" [href]="file.path" class="download">
              <svg height="20" viewBox="0 0 20 20" width="20">
                <path
                  d="M2.4,20A2.5,2.5,0,0,1,0,17.508V11.42a.294.294,0,0,1,.093-.2l3.07-5.918a.432.432,0,0,1,.419-.219H5.349a.489.489,0,0,1,0,.977H3.861L1.3,10.953h2.6A3.047,3.047,0,0,1,6.7,12.836a2.154,2.154,0,0,0,1.953,1.3h2.7a2.154,2.154,0,0,0,1.953-1.3,3.047,3.047,0,0,1,2.791-1.883h2.651L16.582,6.064h-1.93a.489.489,0,0,1,0-.977h2.233a.457.457,0,0,1,.442.244l2.6,5.893a.464.464,0,0,1,.07.2v6.088A2.5,2.5,0,0,1,17.6,20Zm7.279-8.68L6.512,7.973a.511.511,0,0,1,0-.588.453.453,0,0,1,.651-.1L9.535,9.781V.49a.466.466,0,1,1,.93,0V9.781l2.372-2.494a.446.446,0,0,1,.558,0,.507.507,0,0,1,.093.686L10.326,11.32a.284.284,0,0,1-.027.029.428.428,0,0,1-.624-.029Z"
                  fill="#86241d"
                  id="download-btn"
                />
              </svg>
            </a> -->
                            <button (click)="deleteFile(i, file.id)" class="btn-delete">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                                    <path id="Path_19036" data-name="Path 19036"
                                        d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V9a2.006,2.006,0,0,0-2-2H8A2.006,2.006,0,0,0,6,9ZM18,4H15.5l-.71-.71a1.009,1.009,0,0,0-.7-.29H9.91a1.009,1.009,0,0,0-.7.29L8.5,4H6A1,1,0,0,0,6,6H18a1,1,0,0,0,0-2Z"
                                        transform="translate(-5 -3)" fill="#666" />
                                </svg>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>