<div class="files-preview-container">

  <div class="file-input">
      <div class="image-uploader" [ngClass]="wideImage ? 'full-width': '' || wideSquareImage ? 'square-wide': ''">
          <div class="upload-container">
              <label [attr.for]="'fileDropRef_' + managerIndex">
                  <input #fileDropRef type="file"
                      (change)="fileSelected($event)"
                      accept=".png, .jpeg, .jpg"
                      (click)="fileDropRef.value = ''"
                      [disabled]="isDisabled"
                      [hidden]="true"
                      [multiple]="false"
                      [attr.id]="'fileDropRef_' + managerIndex" />
                  <div class="image-preview photo camera-fill">
                    <ng-container *ngIf="src">
                      <img [src]="src" alt="" class="bg-light">
                    </ng-container>
                  </div>
              </label>
          </div>
          <div>
              <label>{{label}}</label>
              <p>
                  {{ 'general.JPG or PNG' | translate }}
                  <span>({{ 'general.MB max' | translate:{no:2} }})</span>
              </p>
              <label [attr.for]="'fileDropRef_' + managerIndex">
                  <a>{{'general.Upload/Select File' | translate }}</a>
              </label>

          </div>
      </div>
  </div>

  <div *ngIf="!src && isRequired && isSubmited" class="has-error">{{"form_message.Required" | translate}} </div>
  <div *ngIf="fileErrors.type" class="has-error">
    {{"fileManager.Invalid File Extensions" | translate}}
  </div>
  <div *ngIf="fileErrors.size" class="has-error">
    {{ "fileManager.Max File Size" | translate: { maxFileSize: maxSize } }}
  </div>
</div>
