// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Dev
  apiUrl: 'https://walaxdevapi.expertapps.com.sa/api',
  socketUrl: 'https://walaxdev.expertapps.com.sa',

  // Test
  // apiUrl: 'https://walaxtestapi.expertapps.com.sa/api',
  // socketUrl: 'https://walaxtest.expertapps.com.sa',

  // Stage
  // apiUrl: 'https://walaxstageapi.expertapps.com.sa/api',
  // socketUrl: 'https://walaxstage.expertapps.com.sa',

  // https://api.offer-x.co/
  // apiUrl: 'https://api.offer-x.co/api',
  // socketUrl: 'https://walaxdev.expertapps.com.sa',


  dashboar_link: "https://walaxdev.expertapps.com.sa",
  mapsApiKey: "AIzaSyDAJBhTNZ8KII7etJztNX1tpfsBXUEqfrw",
  googleSiteKey: '6Lfm3xohAAAAALgLSfUIY8YHdzC16AjLY6wl7U9a',
  googleApiKey: '6Lfm3xohAAAAADEauzDDPzU0YVrIxWTBbi1k3AkE',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
