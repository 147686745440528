import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../services/api-service.service';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthAPIService {

  constructor(private _apiService: ApiServiceService) { }



  ForgetPassword(model: any) {
    return this._apiService.post(`${environment.apiUrl}/web/authentication/forget-password`, model)
      .pipe(catchError(this.handleError));
  }

  ResetPassword(model: any, Token: string) {
    return this._apiService.post(`${environment.apiUrl}/web/authentication/set-my-password/${Token}`, model)
      .pipe(catchError(this.handleError));
  }

  VerifyAccount(model: any, Token: string) {
    return this._apiService.post(`${environment.apiUrl}/web/authentication/set-my-password/${Token}`, model)
      .pipe(catchError(this.handleError));
  }

  CheckToken(Token: string) {
    return this._apiService.get(`${environment.apiUrl}/web/authentication/check-token/${Token}`)
      .pipe(catchError(this.handleError));
  }

  GetCategories() {
    return this._apiService.get(`${environment.apiUrl}/web/categories-with-sub?perPage=50`)
      .pipe(catchError(this.handleError));
  }

  GetHasCategories(model: any) {
    return this._apiService.get(`${environment.apiUrl}/web/categories-has-sub-with-sub`, model)
      .pipe(catchError(this.handleError));
  }

  AddInterstes(model: any) {
    return this._apiService.post(`${environment.apiUrl}/web/interests`, model)
      .pipe(catchError(this.handleError));
  }

  // GetCities(model: any) {
  //   return this._apiService.get(`${environment.apiUrl}/web/cities`, model)
  //     .pipe(catchError(this.handleError));
  // }

   GetCities(model: any) {
    return this._apiService.get(`${environment.apiUrl}/web/all-cities`, model)
      .pipe(catchError(this.handleError));
  }

  GetPackagesWithoutPermission() {
    return this._apiService.get(`${environment.apiUrl}/packages/list`)
      .pipe(catchError(this.handleError));
  }

  RegisterCompany(model: any) {
    return this._apiService.post(`${environment.apiUrl}/register-companies`, model)
      .pipe(catchError(this.handleError));
  }

  getCategoriesWithoutPermission(model: any): any {
    return this._apiService.get(`${environment.apiUrl}/categories-with-children`, model)
      .pipe(catchError(this.handleError));
  }
  // all-cities
  getCitiesWithoutPermission(model: any): any {
    return this._apiService.get(`${environment.apiUrl}/cities`, model)
      .pipe(catchError(this.handleError));
  }

  RegisterVendor(model: any) {
    return this._apiService.post(`${environment.apiUrl}/register-vendors`, model)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }


}
