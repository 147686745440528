import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';

export class Lookup {
  id!: any
  name!: string
  image!: string
  logo!: string
  offer_type: any
  vendor: any
  branches_count!: number
  child!: Lookup[]

  level!: number;
  expandable!: boolean;
  is_checked!: boolean;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  id!: any
  name!: string
  image!: string
  logo!: string
  offer_type: any
  vendor: any
  branches_count!: number
  child!: Lookup[]

  level!: number;
  expandable!: boolean;
  is_checked!: boolean;
}


@Component({
  selector: 'app-tree-selection-with-img',
  templateUrl: './tree-selection-with-img.component.html',
  styles: ``
})
export class TreeSelectionWithImgComponent {

  @Input() isView = false;
  @Input() TREE_DATA: Lookup[] = [];
  @Input() selected: Lookup[] = [];
  @Input() isSubmitted = false;
  @Input() isRequired = false;
  @Input() searchText!: string;

  @Output() selectedItems = new EventEmitter<Lookup[]>();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, Lookup>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<Lookup, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<Lookup, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<Lookup, TodoItemFlatNode>;


  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);



  constructor() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  }

  ngOnInit(): void {
    console.log(this.selected);
    
    if (this.TREE_DATA && this.TREE_DATA.length) {
      this.dataSource.data = this.TREE_DATA;
      this.treeControl.dataNodes.forEach((item: TodoItemFlatNode) => {
        this.childsR(item);
      });
    }
  }

  childsR(item: any) {
    if (item.child && item.child.length) {
      item.child.forEach((element: any) => {
        if (element.child && element.child.length) {
          this.childsR(element);
        }
      });
    } else {
      if (item.is_checked || this.selected.some(x => x.id == item.id)) {
        this.todoLeafItemSelectionToggle(item);
      }
    }
    // this.selectedItem();
  }

  searchNodes() {
    this.treeControl.dataNodes.forEach((item: TodoItemFlatNode) => {
      this.childsR(item);
    });
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: Lookup): Lookup[] => node.child!;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasError() {
    return this.isRequired && this.isSubmitted && !this.selected.length
  }
  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: Lookup, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new TodoItemFlatNode();
    node.level = level;
    node.expandable = !!node.child?.length;

    flatNode.level = level;
    flatNode.expandable = !!node.child?.length;
    flatNode.is_checked = node.is_checked!;

    flatNode.id = node.id;
    flatNode.child = (node.child && node.child.length) ? node.child : [];
    flatNode.name = node.name!;
    flatNode.logo = node.logo!;
    flatNode.image = node.image!;
    flatNode.offer_type = node.offer_type!;
    flatNode.vendor = node.vendor!;
    flatNode.branches_count = node.branches_count!;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }


  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);

    this.selectedItem();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  isInArray(parentObj: any, item: any) {
    return parentObj.some((obj: any) => obj.id === item.id);
  }

  selectedItem() {
    this.selected = [];
    this.checklistSelection.selected.forEach((node: any) => {
      this.addParentsToSelected(node);
      let parent = { ... this.getParentNode(node) };
      if (parent && parent.child) {
        parent.child = [];
        node.vendor = parent
      }
      if ((node.child && !node.child!.length) && !this.isInArray(this.selected, node)) {
        this.selected.push(node);
      }
    });

    this.selectedItems.emit(this.selected);
  }


  addParentsToSelected(node: TodoItemFlatNode) {
    const parentNode = this.getParentNode(node);
    if (parentNode && !this.isInArray(this.selected, parentNode)) {
      this.selected.push(parentNode);
      this.addParentsToSelected(parentNode);
    }
  }

  public hideLeafNode(node: TodoItemFlatNode): boolean {
    return this.isView && !node.is_checked
      ? true
      : new RegExp(this.searchText, 'i').test(node.name) === false;
  }
  public showParentNode(node: TodoItemFlatNode): boolean {
    const parentNode = this.getParentNode(node);
    if (parentNode) {
      return !this.hideLeafNode(parentNode);
    }
    return true;
  }

  public hideParentNode(node: TodoItemFlatNode): boolean {
    return this.treeControl
      .getDescendants(node)
      .filter(node => node.child == null || node.child.length === 0)
      .every(node => this.hideLeafNode(node));
  }

  getSelectedChildCount(node: TodoItemFlatNode): number {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.filter(child => this.checklistSelection.isSelected(child)).length
  }

}

